import { render, staticRenderFns } from "./carousel.vue?vue&type=template&id=c792982e&"
import script from "./carousel.vue?vue&type=script&lang=js&"
export * from "./carousel.vue?vue&type=script&lang=js&"
import style0 from "./carousel.vue?vue&type=style&index=0&id=c792982e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyImage: require('/codebuild/output/src4244354862/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/lazy-image/index.vue').default})
