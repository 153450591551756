import { StyleSizeType } from '@/ui-kit/styles/enums';
import { CAROUSEL_SPACE_MAP } from '@/ui-kit/components/carousel/constants';

export const HOME_FEATURED_IN_CAROUSEL_BREAKPOINTS = {
  [StyleSizeType.XSmall]: {
    slidesPerView: 2,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.Small],
  },
  [StyleSizeType.Small]: {
    slidesPerView: 3,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.Small],
  },
  [StyleSizeType.Medium]: {
    slidesPerView: 4,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.XLarge],
  },
  [StyleSizeType.Large]: {
    slidesPerView: 5,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.Small],
  },
  [StyleSizeType.XLarge]: {
    slidesPerView: 6,
    spaceBetween: CAROUSEL_SPACE_MAP[StyleSizeType.Small],
  },
};

export default {
  HOME_FEATURED_IN_CAROUSEL_BREAKPOINTS,
};
