import { Comparator } from '../enums';
import { Breakpoints } from '../styles/constants';

export const MediaQueryType = {
  [Comparator.Greater]: 'min-width',
  [Comparator.Less]: 'max-width',
};

class MediaQueryHelper {
  static createMediaQuery({
    comparator,
    breakpoint,
    value,
  }) {
    return `(${MediaQueryType[comparator]}: ${Breakpoints[breakpoint]}px) ${value}`;
  }
}

export default MediaQueryHelper;
