
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';

export default {
  name: 'Paper',
  props: {
    className: {
      default: '',
      type: String,
    },
  },
  computed: {
    paperContentClassNames() {
      return new StyleClassNamesBuilder('paper-content')
        .addClassName(this.className)
        .build();
    },
  },
};
