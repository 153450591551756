import { CarouselProperty } from '../enums';
import { StyleColorSchemeType, StyleSizeType } from '../../../styles/enums';

const CarouselProps = {
  props: {
    [CarouselProperty.Height]: {
      default: 0,
      type: Number,
    },
    [CarouselProperty.ColorScheme]: {
      default: StyleColorSchemeType.Secondary,
      type: String,
    },
    [CarouselProperty.SlidesPerView]: {
      default: 1,
      type: Number,
    },
    [CarouselProperty.Pagination]: {
      default: '',
      type: String,
    },
    [CarouselProperty.SpaceSize]: {
      default: StyleSizeType.Medium,
      type: String,
    },
    [CarouselProperty.IsCenteredAfterInit]: {
      default: false,
      type: Boolean,
    },
    [CarouselProperty.CenteredSlides]: {
      default: true,
      type: Boolean,
    },
    [CarouselProperty.Breakpoints]: {
      default: () => ({}),
      type: Object,
    },
    [CarouselProperty.InitialSlide]: {
      default: 0,
      type: Number,
    },
  },
};

export default CarouselProps;
