export const CarouselPropsEventType = {
  ActiveIndexChange: 'activeIndexChange',
  Breakpoint: 'breakpoint',
};

export const CarouselPaginationType = {
  Bullets: 'bullets',
  DynamicBullets: 'dynamicBullets',
  ProgressBar: 'progressbar',
  Fraction: 'fraction',
};

export const CarouselSlotName = {
  Pagination: 'pagination',
  PreviousButton: 'button-prev',
  NextButton: 'button-next',
};

export const CarouselProperty = {
  Height: 'height',
  ColorScheme: 'colorScheme',
  SlidesPerView: 'slidesPerView',
  Pagination: 'pagination',
  SpaceSize: 'spaceSize',
  IsCenteredAfterInit: 'isCenteredAfterInit',
  CenteredSlides: 'centeredSlides',
  Breakpoints: 'breakpoints',
  InitialSlide: 'initialSlide',
};
