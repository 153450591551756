
import ImageHelper from '@/utils/ImageHelper';
import MediaQueryHelper from '@/ui-kit/helpers/MediaQueryHelper';

const EMPTY_PIXEL = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const loadingType = {
  eager: 'eager',
  lazy: 'lazy',
};

export default {
  name: 'LazyImage',
  props: {
    lazy: { type: Boolean, required: false, default: true },
    ratio: { type: [String, Number], required: false, default: '' },
    alt: { type: String, required: true },
    url: { type: String, required: true },
    sizes: { type: Array, required: false, default: () => [] },
    widths: { type: Array, required: false, default: () => [] },
    caption: { type: Boolean, required: false, default: false },
    extraClass: { type: String, required: false, default: '' },
    preload: {
      type: String,
      required: false,
      default: EMPTY_PIXEL,
    },
  },
  data() {
    return {
      nativeLazySupported: ImageHelper.constructor.nativeLazySupported,
      imageRef: 'image',
      destroyCallback: null,
    };
  },
  computed: {
    style() {
      return this.ratio ? `--aspect-ratio:${this.ratio};` : null;
    },
    loading() {
      return this.lazy ? loadingType.lazy : loadingType.eager;
    },
    imageElement() {
      return this.$refs[this.imageRef];
    },
    sizesAttribute() {
      return this.sizes.map(({ comparator, breakpoint, imageSize }) => (
        MediaQueryHelper.createMediaQuery({
          comparator,
          breakpoint,
          value: `${imageSize}px`,
        })
      ));
    },
  },
  mounted() {
    this.loadImage();
  },
  beforeDestroy() {
    if (this.destroyCallback) {
      this.destroyCallback();
    }
  },
  updated() {
    if (this.imageElement) {
      ImageHelper.constructor.updateLazyImage(this.imageElement);
    }
  },
  methods: {
    generateSrcSet() {
      const widths = this.sizes.length ? this.sizes.map(({ imageSize }) => imageSize) : this.widths;

      return ImageHelper.constructor.getSrcSet(this.url, widths);
    },
    loadImage() {
      if (this.imageElement) {
        this.destroyCallback = ImageHelper.constructor.loadImage(this.imageElement);
      }
    },
  },
};
