
import { SwiperSlide, directive } from 'vue-awesome-swiper';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';
import CarouselItemProps from './mixins/CarouselItemProps';
import { CarouselItemProperty } from './enums';

export default {
  name: 'CarouselItem',
  components: {
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  mixins: [CarouselItemProps],
  computed: {
    carouselItemClassName() {
      return new StyleClassNamesBuilder('carousel-item')
        .addClassName(this[CarouselItemProperty.ClassName])
        .build();
    },
  },
};
