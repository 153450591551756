
import { Swiper, directive } from 'vue-awesome-swiper';
import { Breakpoints } from '../../styles/constants';
import { StyleSizeType, StylePositionType } from '../../styles/enums';
import Gutter from '../gutter';
import {
  CAROUSEL_SPACE_MAP,
  DEFAULT_CAROUSEL_BREAKPOINT,
  PAGINATION_CONFIG_BY_TYPE,
} from './constants';
import CarouselProps from './mixins/CarouselProps';
import { CarouselPropsEventType, CarouselSlotName, CarouselProperty } from './enums';
import StyleClassNamesBuilder from '../../styles/helpers/StyleClassNamesBuilder';

// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import 'swiper/css/swiper.css';

export default {
  name: 'Carousel',
  components: {
    Gutter,
    Swiper,
  },
  directives: {
    swiper: directive,
  },
  mixins: [CarouselProps],
  data() {
    return {
      paginationElementClass: 'swiper-pagination',
      previousButtonElementClass: 'swiper-previous-button',
      nextButtonElementClass: 'swiper-next-button',
      StylePositionType,
      StyleSizeType,
      CarouselProperty,
      CarouselSlotName,
    };
  },
  computed: {
    carouselClassNames() {
      return new StyleClassNamesBuilder('carousel')
        .addColorSchemeConditionClassNames(this[CarouselProperty.ColorScheme])
        .addClassName('swiper')
        .build();
    },
    carouselOptions() {
      return {
        slidesPerView: this[CarouselProperty.SlidesPerView],
        spaceBetween: CAROUSEL_SPACE_MAP[this[CarouselProperty.SpaceSize]],
        centeredSlides: this[CarouselProperty.CenteredSlides],
        pagination: this.paginationOptions,
        breakpoints: this.getConvertedBreakpoints(),
        initialSlide: this[CarouselProperty.InitialSlide],
        navigation: this.navigationOptions,
      };
    },
    paginationOptions() {
      return this[CarouselProperty.Pagination] && {
        el: `.${this.paginationElementClass}`,
        ...PAGINATION_CONFIG_BY_TYPE[this[CarouselProperty.Pagination]],
      };
    },
    navigationOptions() {
      return {
        nextEl: `.${this.nextButtonElementClass}`,
        prevEl: `.${this.previousButtonElementClass}`,
      };
    },
  },
  mounted() {
    this.handleActiveIndexChange();
    this.handleBreakpointChange();

    if (this[CarouselProperty.IsCenteredAfterInit]) {
      this.slideToCenter();
    } else {
      this.slideToStartIndex();
    }
    this.getCarouselRef().on(CarouselPropsEventType.ActiveIndexChange, () => {
      this.handleActiveIndexChange();
    });
    this.getCarouselRef().on(CarouselPropsEventType.Breakpoint, () => {
      this.handleBreakpointChange();
    });
  },
  methods: {
    onChangeCurrentIndex(index) {
      this.$emit(CarouselPropsEventType.ActiveIndexChange, index);
    },
    handleBreakpointChange() {
      this.$emit(CarouselPropsEventType.Breakpoint, this.getCurrentBreakpoint());
    },
    handleActiveIndexChange() {
      this.onChangeCurrentIndex(this.getCurrentIndex());
    },
    getConvertedBreakpoints() {
      const sizeKeys = Object.keys(this[CarouselProperty.Breakpoints])
        .map((size) => {
          const newKey = Breakpoints[size];

          return { [newKey]: this[CarouselProperty.Breakpoints][size] };
        });

      return Object.assign({}, ...sizeKeys);
    },
    slideTo(index) {
      this.onChangeCurrentIndex(index);
      this.getCarouselRef().slideTo(index, 0);
    },
    slideToCenter() {
      const centerElementNumber = Math.ceil(this.getNumberOfItems() / 2);
      const centerElementIndex = centerElementNumber - 1;

      this.slideTo(centerElementIndex);
    },
    slideToStartIndex() {
      this.slideTo(this[CarouselProperty.InitialSlide]);
    },
    getCarouselRef() {
      return this.$refs.carousel.$swiper;
    },
    getNumberOfItems() {
      return this.getCarouselRef().slidesGrid.length;
    },
    getCurrentIndex() {
      return this.getCarouselRef().realIndex;
    },
    getCurrentBreakpoint() {
      const breakpoint = this.carouselOptions.breakpoints[this.getCarouselRef().currentBreakpoint];

      return breakpoint || DEFAULT_CAROUSEL_BREAKPOINT;
    },
    getHasSlot(slotType) {
      return !!this.$slots[slotType];
    },
  },
};
