import { CarouselItemProperty } from '../enums';

const CarouselItemProps = {
  props: {
    [CarouselItemProperty.ClassName]: {
      default: '',
      type: String,
    },
  },
};

export default CarouselItemProps;
