/* eslint-disable class-methods-use-this */
const LAZY_DONE_CLASS = 'lazy-done';
const EMTY_FUNCTION = () => {};

class ImageHelper {
  async restrictWidth(dataURI, maxWidth) {
    const img = await this.dataURIToImage(dataURI);
    let scale = 1;
    if (img.width > maxWidth) {
      scale = maxWidth / img.width;
    }
    if (scale === 1) return dataURI;

    return this.drawImage(img, scale);
  }

  async restrictHighestDimension(dataURI, maxSize) {
    const img = await this.dataURIToImage(dataURI);
    let scale = 1;
    if (img.width > maxSize) {
      scale = maxSize / img.width;
    }
    if (img.height > maxSize) {
      const scale2 = maxSize / img.height;
      if (scale2 < scale) scale = scale2;
    }
    if (scale === 1) return dataURI;

    return this.drawImage(img, scale);
  }

  async rotateLeft(dataURI) {
    const img = await this.dataURIToImage(dataURI);
    return this.drawImage(img, 1, true);
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  async dataURIToImage(dataURI) {
    const img = new Image();
    return new Promise((resolve, reject) => {
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
      img.src = dataURI;
    });
  }

  drawImage(img, scale = 1, rotate = false) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width * scale;
    canvas.height = img.height * scale;
    if (rotate) {
      canvas.width = img.height * scale;
      canvas.height = img.width * scale;
      ctx.translate(canvas.width, 0);
      ctx.rotate((90 * Math.PI) / 180);
      ctx.drawImage(img, 0, 0, canvas.height, canvas.width);
    } else {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    }
    return canvas.toDataURL('image/jpeg');
  }

  /*
  * Evaluates the size of Base64 blob image
  */
  evluateBlobSize(blobImage, unit = 'kb') {
    let lastCharcters = blobImage.substr(blobImage.length - 2);
    if (lastCharcters === 2) {
      lastCharcters = 2;
    } else {
      lastCharcters = 1;
    }
    const sizeInBytes = (blobImage.length * (3 / 4)) - lastCharcters;
    if (unit.toLowerCase() === 'kb') {
      return (sizeInBytes / 1000);
    } if (unit.toLowerCase() === 'mb') {
      return (sizeInBytes / 1000000);
    }
    return sizeInBytes;
  }

  static nativeLazySupported = (process.server || 'loading' in HTMLImageElement.prototype);

  static setLazyImage(image) {
    if (!image.classList.contains(LAZY_DONE_CLASS)) {
      this.setLazyImageAttributes(image);
      image.classList.add(LAZY_DONE_CLASS);
    }
  }

  static updateLazyImage(image) {
    if (image.classList.contains(LAZY_DONE_CLASS)) {
      this.setLazyImageAttributes(image);
    }
  }

  static setLazyImageAttributes(image) {
    // eslint-disable-next-line no-param-reassign
    image.srcset = image.dataset.srcset;
    // eslint-disable-next-line no-param-reassign
    image.sizes = image.dataset.sizes;
  }

  static loadImage(image) {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((changes) => {
        changes.forEach((change) => {
          if (change.isIntersecting) {
            this.setLazyImage(image);
            observer.unobserve(image);
          }
        });
      });

      observer.observe(image);

      return () => observer.disconnect();
    }

    this.setLazyImage(image);

    return EMTY_FUNCTION;
  }

  static getSrcSet(baseUrl, sizes) {
    return sizes.map((size) => `${baseUrl}&w=${size} ${size}w`).join(', ');
  }

  static getImageRatio({ width, height }) {
    return width / height;
  }
}

module.exports = new ImageHelper();
