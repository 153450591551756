
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import Carousel from '@/ui-kit/components/carousel';
import CarouselItem from '@/ui-kit/components/carousel-item';
import { CarouselPaginationType } from '@/ui-kit/components/carousel/enums';
import Paper from '@/ui-kit/components/paper';
import {
  HOME_FEATURED_IN_CAROUSEL_BREAKPOINTS,
} from './constants';
import HomeStoreName from '@/store/home/name';
import { Comparator } from '@/ui-kit/enums';
import { HomePageEvent } from '@/constants/AnalyticEvents';

export default {
  name: 'HomeFeaturedInCarousel',
  components: { Carousel, CarouselItem, Paper },
  data() {
    return {
      HOME_FEATURED_IN_CAROUSEL_BREAKPOINTS,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
      HomePageEvent,
      CarouselPaginationType,
    };
  },
  computed: {
    imageSizes() {
      return [
        {
          comparator: Comparator.Less,
          breakpoint: StyleSizeType.Medium,
          imageSize: 160,
        },
        {
          comparator: Comparator.Greater,
          breakpoint: StyleSizeType.Medium,
          imageSize: 160,
        },
      ];
    },
    featuredInContent() {
      return this.$store.state[HomeStoreName].featuredInContent;
    },
  },
};
