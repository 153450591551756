import { StyleSizeType } from '../../../styles/enums';
import { CarouselPaginationType } from '../enums';

export const CAROUSEL_SPACE_MAP = {
  [StyleSizeType.Small]: 10,
  [StyleSizeType.Medium]: 22,
  [StyleSizeType.Large]: 35,
  [StyleSizeType.XLarge]: 60,
  [StyleSizeType.XXLarge]: 85,
};
export const PAGINATION_CONFIG_BY_TYPE = {
  [CarouselPaginationType.Bullets]: { clickable: true },
  [CarouselPaginationType.DynamicBullets]: { dynamicBullets: true, clickable: true },
  [CarouselPaginationType.ProgressBar]: { type: CarouselPaginationType.ProgressBar },
  [CarouselPaginationType.Fraction]: { type: CarouselPaginationType.Fraction },
};
export const DEFAULT_CAROUSEL_BREAKPOINT = {
  slidesPerView: 1,
};

export default {
  CAROUSEL_SPACE_MAP,
  PAGINATION_CONFIG_BY_TYPE,
};
